{
  "common": {
    "and": "and",
    "show": "Show",
    "showAll": "Display all",
    "search": "Search",
    "showMore": "Display more",
    "hide": "Hide",
    "more": "More",
    "readMore": "Read more",
    "notChosen": "No select",
    "noOptions": "Sorry, no matching options",
    "unitsOfMeasurement": {
      "label": "Units of measurement",
      "options": {
        "squareMeter": "m²",
        "squareFoot": "ft²"
      }
    },
    "from": "from",
    "to": "to",
    "of": "of",
    "in": "in",
    "or": "or",
    "yes": "yes",
    "no": "no",
    "go": "Open",
    "send": "Send",
    "add": "Add",
    "edit": "Edit",
    "cancel": "Cancel",
    "details": "Details",
    "inDevelopment": "Functionality is under development",
    "supportRequestMessage": "An error occurred while running the application. Please contact technical support",
    "weekdays": {
      "short": {
        "monday": "Mo",
        "tuesday": "Tu",
        "wednesday": "We",
        "thursday": "Th",
        "friday": "Fr",
        "saturday": "Sa",
        "sunday": "Su"
      }
    },
    "months": {
      "full": {
        "january": "January",
        "february": "February",
        "march": "March",
        "april": "April",
        "may": "May",
        "june": "June",
        "july": "July",
        "august": "August",
        "september": "September",
        "october": "October",
        "november": "November",
        "december": "December"
      },
      "short": {
        "january": "Jan",
        "february": "Feb",
        "march": "Mar",
        "april": "Apr",
        "may": "May",
        "june": "Jun",
        "july": "Jul",
        "august": "Aug",
        "september": "Sep",
        "october": "Oct",
        "november": "Nov",
        "december": "Dec"
      },
      "genitive": {
        "january": "January",
        "february": "February",
        "march": "March",
        "april": "April",
        "may": "May",
        "june": "June",
        "july": "July",
        "august": "August",
        "september": "September",
        "october": "October",
        "november": "November",
        "december": "December"
      }
    },
    "time": {
      "at": "at",
      "from": "from",
      "to": "to",
      "up": "to",
      "label": {
        "from": "Time from",
        "to": "Time to"
      }
    },
    "terms": {
      "day": "0 days | {count} day | {count} days"
    },
    "today": "Today",
    "tomorrow": "Tomorrow",
    "upload": "Upload",
    "available": "Available",
    "language": "Language",
    "download": "Download",
    "all": "All",
    "contacts": "Contacts",
    "save": "Save",
    "noPhoto": "No photo",
    "countries": {
      "prepositional": {
        "ru": "Russia",
        "ge": "Georgia",
        "kz": "Kazakhstan",
        "uz": "Uzbekistan",
        "ae": "UAE",
        "id": "Indonesia",
        "cy": "Cyprus",
        "th": "Thailand"
      }
    },
    "downloadApp": "Download our application"
  },
  "pageTitles": {
    "home": "Home",
    "login": "Login",
    "signup": "Sign Up",
    "fixation": "Fixation",
    "signupInvitation": "Sign Up - Invitation",
    "forgotPassword": "Forgot Password",
    "buildingPage": "{value}",
    "presentationBuildingPage": "{value}",
    "presentationBuildingPageFromSuggestion": "{value}",
    "market": "Market",
    "suggestions": "Suggestions",
    "suggestionPage": "Suggestion №{value}",
    "notFound": "Page Not Found",
    "forbidden": "Access Forbidden",
    "suggestionsManagement": "Suggestions Management",
    "clients": "Clients",
    "changeList": "Updates",
    "developerPage": "{value}",
    "journal": "Journal",
    "journalNews": "News",
    "profileDelete": "Account Deleting"
  },
  "feedback": {
    "label": "Feedback",
    "options": {
      "suggestIdea": "Suggest an idea",
      "dataError": "Report an inaccuracy in the data",
      "serviceError": "Report a service malfunction"
    }
  },
  "favoritesLinks": {
    "label": "Favorites",
    "reset": "Reset",
    "add": "Add page",
    "confirm": {
      "title": "Are you sure you want to reset all selected pages?",
      "toasts": {
        "success": "Selected pages reset successfully",
        "error": "An error occurred while resetting selected pages"
      }
    }
  },
  "cookie": {
    "title": "Cookie Notification",
    "text": "We use cookies to make the site more convenient and secure. By using this website, you consent to the use of cookies.",
    "readMore": "Read more",
    "accept": "OK, don't show it anymore"
  },
  "currency": {
    "label": "Currency",
    "options": {
      "rub": "₽",
      "usd": "$",
      "eur": "€",
      "kzt": "₸",
      "thb": "฿",
      "gbp": "£"
    }
  },
  "pagination": {
    "goToPage": "Go to"
  },
  "auth": {
    "description": {
      "label": "Estatyx",
      "welcome": "Welcome!",
      "into": "Login please"
    },
    "login": {
      "label": "Sign in",
      "iAgreeText": "I agree to the processing of personal data in accordance with",
      "privatePolicy": "privacy policy",
      "licenseAgreement": "terms of the license agreement"
    },
    "resetPassword": {
      "label": "Reset Password",
      "description": "Your new password must be different from previously used passwords.",
      "setPassword": "Set New Password",
      "toast": {
        "success": "Password successfully changed",
        "error": "An error occurred while changing the password"
      }
    },
    "signup": {
      "label": "Sign up",
      "iAgreeText": "I agree to the processing of personal data in accordance with",
      "privatePolicy": "privacy policy",
      "licenseAgreement": "terms of the license agreement"
    },
    "register": {
      "label": "Signup",
      "toast": {
        "label": {
          "success": "You have successfully registered!",
          "error": "An error occurred during registration"
        }
      }
    },
    "organisation": {
      "description": {
        "selectOrganisation": "You have to select an organization",
        "someOrganisation": "You are registered in several organizations. Select the organization where you want to continue working in the application or create a new one.",
        "attention": "Warning!",
        "warning": "By clicking the \"Sign in\" button, a new organization will be created"
      },
      "actions": {
        "selectOrganisation": {
          "error": "An error occurred while selecting an organization"
        },
        "createOrganisation": {
          "label": "Create a new organization",
          "error": "An error occurred during the creation of the organization"
        }
      },
      "fired": {
        "title": "Access Restricted",
        "error": "To restore access, contact the person responsible in your organization or create a new organization",
        "create": "Create an organization"
      }
    },
    "password": {
      "label": "Password",
      "placeholder": "Enter the password"
    },
    "passwordConfirm": {
      "label": "Password confirmation",
      "placeholder": "Repeat password",
      "error": "Passwords do not match"
    },
    "forgotPassword": {
      "label": "Forgot password?",
      "description": "Enter your email address and we will send you instructions on how to reset your password.",
      "send": "Send",
      "toast": {
        "label": {
          "success": "Password recovery link has been sent to email: {value}!",
          "error": "An error occurred while sending the password to your email"
        }
      }
    },
    "haveAccount": {
      "label": "Already have an account?"
    },
    "username": {
      "label": "Username",
      "placeholder": "Enter email or phone number",
      "error": "Field must be a phone number or email address"
    },
    "name": {
      "label": "Name",
      "placeholder": "Enter name",
      "error": "Name field is required"
    },
    "lastName": {
      "label": "Last name",
      "placeholder": "Enter last name"
    },
    "userEmail": {
      "label": "E-mail",
      "placeholder": "Enter email",
      "error": "Field must be an email address"
    },
    "userPhone": {
      "label": "Phone number",
      "placeholder": "Enter phone number",
      "error": "Field must be a phone number"
    },
    "backendValidation": {
      "error": "Invalid username or password"
    },
    "invitationSignup": {
      "messages": {
        "success": "The account you are creating will be added to the organization {value}.",
        "linkError": "The invitation link is incorrect. Request a new link or contact technical support at {value}",
        "error": "Failed to retrieve organization information. Error: {value}"
      },
      "toLogin": "Join with an existing account",
      "loginInfoMessage": "Your account will be added to the organization {value}"
    },
    "exchangeToken": {
      "toast": {
        "label": {
          "error": "Failed to authorize in CRM"
        }
      }
    }
  },
  "organisation": {
    "form": {
      "id": {
        "label": "Organization ID"
      },
      "createAt": {
        "label": "Organization connection"
      },
      "name": {
        "label": "Name",
        "placeholder": "Enter organization name"
      },
      "email": {
        "label": "E-mail",
        "placeholder": "Enter email"
      },
      "phone": {
        "label": "Main phone",
        "placeholder": "+ 7 901 234 56 78"
      },
      "site": {
        "label": "Organisation's website",
        "placeholder": "Enter the website or leave the field blank"
      },
      "country": {
        "label": "Country"
      },
      "type": {
        "label": "Account Type"
      },
      "city": {
        "label": "City",
        "placeholder": {
          "label": "Enter city and select from the list"
        }
      },
      "actions": {
        "save": {
          "label": "Save"
        },
        "fireUser": {
          "label": "Are you sure you want to fire  {firstName} {lastName} ?"
        }
      },
      "toast": {
        "success": {
          "label": "Organization data saved successfully!"
        },
        "error": {
          "label": "An error occurred while saving the data."
        }
      },
      "toasts": {
        "fireUser": {
          "success": "User successfully fired!",
          "error": "An error occurred while firing the user"
        },
        "updateRole": {
          "success": "Status successfully updated!",
          "error": "An error occurred while updating the status"
        }
      }
    },
    "types": {
      "agency": {
        "label": "Real Estate Agency"
      },
      "privateRealtor": {
        "label": "Private Realtor"
      },
      "developer": {
        "label": "Developer"
      },
      "integrator": {
        "label": "Integrator/Bitrix24 Partner"
      },
      "managementCompany": {
        "label": "Management Company"
      },
      "other": {
        "label": "Other"
      }
    },
    "moderation": {
      "onModeration": {
        "label": "In verification",
        "title": "Your organization is in verification",
        "text": "Access to the service will be granted after verification"
      },
      "denied": {
        "label": "Access Restricted",
        "title": "Access Restricted",
        "text": "Please contact the partner manager for more details"
      },
      "contacts": {
        "label": "Contact Information"
      }
    },
    "settings": {
      "page": {
        "toggle": {
          "options": {
            "profile": "Profile",
            "settings": "Settings",
            "integration": "Integration",
            "employees": "Employees",
            "roles": "Roles"
          }
        },
        "profile": {
          "title": "Company profile"
        },
        "settings": {
          "title": "Settings"
        },
        "integration": {
          "title": "Integration"
        },
        "employees": {
          "title": "Employees",
          "invitation": {
            "link": "Invite link",
            "tooltip": {
              "copy": "Copy",
              "change": "Change"
            }
          },
          "table": {
            "head": {
              "id": "ID",
              "phone": "Phone",
              "email": "Email",
              "role": "Role"
            }
          }
        },
        "roles": {
          "title": "Roles",
          "user": "User",
          "admin": "Administrator"
        }
      },
      "form": {
        "profile": {
          "id": "Organization ID:",
          "connectionTime": "Organization connection:",
          "about": {
            "title": "About company",
            "name": {
              "label": "Name",
              "placeholder": "Organisation name"
            },
            "type": {
              "label": "Company type"
            }
          },
          "address": {
            "title": "Location",
            "country": {
              "label": "Choose country",
              "placeholder": "Country"
            },
            "city": {
              "label": "Choose city",
              "placeholder": "City"
            }
          },
          "contacts": {
            "title": "Contacts",
            "phone": {
              "label": "Phone",
              "placeholder": "+7 000 000 00 00"
            },
            "email": {
              "label": "E-mail"
            }
          }
        }
      }
    }
  },
  "validation": {
    "messages": {
      "min": "The {label} field must be at least {length} characters long",
      "minValue": "The {label} field must be at least {min}",
      "max": "The {label} field must be a maximum of {length} characters long",
      "required": "{label} is a required field",
      "url": "The {label} field must be a valid URL",
      "oneFieldRequired": "{firstLabel} field is required if \"{secondLabel}\" field is empty"
    }
  },
  "address": {
    "select": {
      "button": {
        "label": "Country"
      },
      "modal": {
        "title": {
          "choose": "Choose",
          "country": "Country",
          "region": "Region",
          "city": "City"
        }
      }
    },
    "allCountries": "All countries",
    "country": {
      "placeholder": "Enter a country"
    },
    "region": {
      "placeholder": "Enter region",
      "empty": "No residential complexes found"
    },
    "city": {
      "placeholder": "Enter city"
    },
    "toast": {
      "error": "An error occurred while loading"
    }
  },
  "building": {
    "entityName": "Residential complexes",
    "name": {
      "default": "Untitled"
    },
    "promotions": {
      "label": "Promotions building",
      "allPromotions": "Promotions of all building",
      "showAllPromotions": "View promotions of all building",
      "showButton": "View",
      "promotionsNotFound": "Promotions not found",
      "priceWithoutPromo": "The price is shown without promotions and discounts",
      "promotionInterval": "The promotion runs",
      "unlimited": "indefinitely"
    },
    "classes": {
      "label": "Residential Complex Class",
      "business": {
        "label": "Business"
      },
      "comfort": {
        "label": "Comfort"
      },
      "economy": {
        "label": "Economy"
      },
      "elite": {
        "label": "Elite"
      }
    },
    "developers": {
      "notFound": "Unfortunately, there is currently no statistics on developers in the selected region"
    },
    "banks": {
      "notFound": "Unfortunately, there is currently no statistics on banks in the selected region"
    },
    "agencies": {
      "notFound": "Unfortunately, there is currently no statistics on agencies in the selected region"
    },
    "popular": {
      "label": "Popular Residential Complexes",
      "jkNotFound": "Unfortunately, there are no statistics on popular residential complexes in your chosen region yet"
    },
    "constructionProgress": {
      "quarter": {
        "label": "{value} quarter"
      },
      "photos": {
        "label": {
          "full": "no photos | {count} photo | {count} photos | {count} photos"
        }
      },
      "video": {
        "label": {
          "full": "no video | {count} video | {count} videos | {count} videos"
        }
      },
      "options": {
        "all": "All houses",
        "building": "General plan"
      }
    },
    "houses": {
      "label": {
        "short": "no buildings | {count} bldg | {count} bldgs | {count} bldgs",
        "full": "no buildings | {count} building | {count} buildings | {count} buildings"
      },
      "allHouses": "All buildings"
    },
    "contacts": {
      "label": "Contacts",
      "forCommunication": "Contact information"
    },
    "withoutContacts": {
      "label": "No contacts"
    },
    "timeConstraints": {
      "quarter": {
        "label": {
          "short": "qtr."
        },
        "options": {
          "first": "1",
          "second": "2",
          "third": "3",
          "fourth": "4"
        }
      },
      "year": {
        "label": {
          "short": "yr.",
          "full": "year"
        }
      },
      "yearQuarter": "{quarter} qtr. {year}",
      "salesStart": {
        "label": "Sales Start"
      },
      "keysGetting": {
        "label": "Keys Getting",
        "gettingNow": "In Progress"
      },
      "deadLine": {
        "label": "Deadline"
      }
    },
    "flatsInfo": {
      "flats": {
        "label": {
          "full": "Flats",
          "short": "Flats"
        },
        "count": "Count",
        "fullCountLabel": "Number of flats for sale",
        "flatsTotalCountLabel": "Number of flats"
      },
      "flatRoomsCount": {
        "label": "Rooms",
        "one": "1",
        "two": "2",
        "three": "3",
        "four": "4",
        "five": "5",
        "six": "6",
        "seven": "7",
        "eight": "8",
        "nine": "9+",
        "studio": "Studio",
        "notIndicated": {
          "label": "Number of rooms not indicated",
          "full": "not indicated",
          "short": "not ind."
        },
        "openPlan": "Open Plan"
      },
      "flatRoomsCountBuildingCard": {
        "one": "1 bdrm",
        "two": "2 bdrm",
        "three": "3 bdrm",
        "four": "4 bdrm",
        "five": "5 bdrm",
        "six": "6 bdrm",
        "seven": "7 bdrm",
        "eight": "8 bdrm",
        "nine": "9+ bdrm",
        "studio": "studio",
        "notIndicated": {
          "label": "Number of rooms not indicated",
          "full": "not indicated",
          "short": "not ind."
        },
        "openPlan": "Open Plan"
      },
      "totalArea": {
        "label": "Area",
        "from": "from {value} sqm"
      },
      "price": {
        "label": "Price",
        "from": "from {value}"
      }
    },
    "metroInfo": {
      "distance": "{value} min"
    },
    "motivation": {
      "label": "Motivation"
    },
    "escrow": {
      "label": "Escrow",
      "options": {
        "true": "Yes",
        "false": "No"
      }
    },
    "actions": {
      "motivation": {
        "label": "Motivation",
        "commission": "Commission up to {value}%",
        "linkDescriptionPartOne": "Terms of payment and amount",
        "linkDescriptionPartSecond": "commission read on",
        "link": "link",
        "commissionSum": "Up to {amount} {currency}",
        "notFound": "Commission not specified"
      },
      "generalPlan": {
        "label": "Site Plan"
      },
      "videoReview": {
        "label": "Video Review"
      },
      "video": {
        "label": "Video"
      },
      "map": {
        "label": "Map"
      },
      "layout": {
        "label": "Finishing"
      },
      "panorama": {
        "label": "Panorama"
      },
      "aerialPanorama": {
        "label": "Aeropanorama"
      },
      "addToSuggestion": {
        "label": "Add to Collection"
      },
      "priceStatistics": {
        "label": "Price dynamics per m²"
      },
      "addedToSuggestion": {
        "label": "Added to Collection"
      },
      "linkToSuggestion": {
        "label": "Link to Collection"
      },
      "linkToPresentation": {
        "label": "Link to Presentation",
        "toast": {
          "success": "Link copied"
        }
      },
      "lookAtTheMap": {
        "label": "View on Map"
      },
      "booking": {
        "label": "Book"
      },
      "fixingTheClient": {
        "label": "Client Fixation"
      },
      "fixViewing": {
        "label": "Fix view"
      },
      "constructionProgress": {
        "label": "Construction Progress",
        "emptyConstructionProgress": "No information available on construction progress."
      },
      "addToFavorites": {
        "label": "Add to Favorites"
      },
      "addToComparison": {
        "label": "Add to Comparison"
      },
      "lookAtTheCondition": {
        "label": "Terms / Remuneration / Regulations"
      },
      "chessboard": {
        "label": "Chessboard"
      }
    },
    "page": {
      "media": "Photos and Videos",
      "totalArea": "Flats",
      "about": "About {value}",
      "decoration": "Finishing",
      "advantages": "Advantages",
      "flats": {
        "label": {
          "full": "Flats",
          "short": "Flats"
        }
      },
      "constructionProgress": {
        "label": {
          "full": "Construction Progress",
          "short": "Constr. Prog."
        }
      },
      "files": "Files",
      "mortgage": "Mortgage",
      "ambassador": {
        "title": "Find out everything from an expert",
        "subtitle": "We are the only company on the market that offers the largest selection of real estate and conducts liquidity analysis of each property. Estatyx specialists know how to safely purchase your dream apartment and receive high income from it.",
        "userInfo": "Ambassador in {country}",
        "actions": {
          "contactExpert": {
            "label": "Contact an expert",
            "messageForWhatsApp": "Good day.%0AI'm here about the housing complex \"{buildingName}\"%0A{buildingLink}"
          },
          "bookViewing": {
            "label": "Book Viewing",
            "messageForWhatsApp": "Good day.%0AI want to schedule a viewing of the housing complex \"{buildingName}\"%0A{buildingLink}"
          }
        }
      }
    },
    "image": {
      "alt": "Residential complex image"
    },
    "state": {
      "label": "Building Construction Stage",
      "viewLabel": "building construction stage",
      "viewValue": "%{value}",
      "options": {
        "built": "Building constructed but not handed over",
        "handOver": "Handed over",
        "unfinished": "Under construction"
      }
    },
    "presentation": {
      "actions": {
        "returnToSuggestion": {
          "label": "Back to Collection"
        }
      },
      "bookViewing": {
        "textToSend": "I want to schedule a viewing of {building}. When can we see it?"
      },
      "flats": {
        "houses": {
          "managerChosen": "Properties chosen by the realtor"
        }
      }
    }
  },
  "flat": {
    "entityName": "Flats",
    "popular": {
      "label": "Popular Flats",
      "flatsNotFound": "Unfortunately, there are no statistics on popular flats in your chosen region yet"
    },
    "decorationType": {
      "label": "Finishing",
      "emptyDecoration": "No information available on finishes.",
      "default": "Default Finishing",
      "options": {
        "label": "Finishing Options",
        "withoutPreparation": {
          "short": "no prep.",
          "full": "Without Finishing"
        },
        "preparation": {
          "short": "prep.",
          "full": "Preparation"
        },
        "preFinishing": {
          "short": "pre-fin.",
          "full": "Pre-Finishing"
        },
        "fine": {
          "short": "fine",
          "full": "Fine Finishing"
        },
        "furnished": {
          "short": "furn.",
          "full": "Furnished"
        },
        "partlyFurnished": {
          "short": "part. furn.",
          "full": "Partly Furnished"
        }
      }
    },
    "windowViewType": {
      "label": "Window View",
      "options": {
        "street": {
          "label": "Street View"
        },
        "yard": {
          "label": "Courtyard View"
        },
        "mountains": {
          "label": "Mountain View"
        },
        "yardAndStreet": {
          "label": "Courtyard and Street View"
        },
        "sea": {
          "label": "Sea View"
        },
        "river": {
          "label": "River View"
        },
        "city": {
          "label": "City view"
        }
      }
    },
    "cardinalDirections": {
      "options": {
        "north": {
          "label": "north"
        },
        "south": {
          "label": "south"
        },
        "west": {
          "label": "west"
        },
        "east": {
          "label": "east"
        },
        "northeast": {
          "label": "northeast"
        },
        "southeast": {
          "label": "southeast"
        },
        "southwest": {
          "label": "southwest"
        },
        "northwest": {
          "label": "northwest"
        }
      }
    },
    "paymentType": {
      "label": "Payment",
      "options": {
        "mortgage": "Mortgage",
        "cash": "Cash",
        "certificate": "Certificate",
        "maternityCapital": "Maternity Capital",
        "installmentPlan": "Installment Plan"
      }
    },
    "contractType": {
      "label": "Contract",
      "options": {
        "assignment": "Assignment",
        "purchaseAndSaleAgreement": "Purchase and Sale Agreement"
      }
    },
    "totalArea": {
      "label": "Total Area",
      "value": "{value} sqm",
      "from": "from {value} sqm",
      "to": "up to {value} sqm"
    },
    "kitchenArea": {
      "label": "Kitchen Area",
      "value": "{value} sqm"
    },
    "floorNumber": {
      "label": "Floor",
      "value": "{value}",
      "from": "from {value}",
      "to": "up to {value}",
      "action": {
        "label": "Select Floor"
      }
    },
    "lastFloor": {
      "label": "Top Floor"
    },
    "notFirstFloor": {
      "label": "Not Ground Floor"
    },
    "price": {
      "from": "from {amount} {currency}",
      "to": "up to {amount} {currency}"
    },
    "pricePerSquareMeter": "{amount} {currency}/sqm",
    "status": {
      "label": "Availability",
      "options": {
        "free": "Available",
        "booked": "Booked",
        "sold": "Sold",
        "unavailable": "Unavailable"
      }
    },
    "flatNumber": "Flat No. {value}",
    "section": "Section {value}",
    "table": {
      "entityName": "Table",
      "section": "Section",
      "floor": "Floor",
      "flatNumber": "Flat No.",
      "totalArea": "Total Area",
      "kitchenArea": "Kitchen Area",
      "windowViewType": "View from the window",
      "cardinalDirection": "Cardinal direction",
      "price": "Total Price",
      "pricePerSquareMeter": "Price per sqm",
      "status": "Status",
      "building": "Building",
      "agentMotivation": "Motivation"
    },
    "card": {
      "flatNumber": "No. {value}"
    },
    "grid": {
      "entityName": "Grid"
    },
    "chessboard": {
      "entityName": "Flats on Chessboard"
    },
    "order": {
      "full": "{value} order",
      "short": "{value} ord."
    },
    "button": {
      "book": {
        "label": "Book"
      },
      "showAvailableFlats": {
        "show": {
          "label": "Show Unavailable Flats"
        },
        "hide": {
          "label": "Hide Unavailable Flats"
        }
      },
      "bookViewing": {
        "label": "Book Viewing",
        "messageForWhatsApp": "I want to schedule a viewing of Flat No. {flatNumber} in {buildingName}, House {houseName}%0APrice - {price}%0ATotal Area - {totalArea}%0ALayout - {layoutName}%0AFinishing - {decorationType}%0A%0AWhen can we see it?",
        "messageInLayoutForWhatsApp": "I want to schedule a viewing of Flat with Layout - {layoutName} in {buildingName}, House {houseName}%0APrice - {price}%0ATotal Area - {totalArea}%0A%0AWhen can we see it?",
        "messageDefaultField": "not specified"
      },
      "bookFlat": {
        "label": "Book an apartment",
        "messageForWhatsApp": "I want to book an apartment No. {flatNumber} in {buildingName}, House {houseName}%0APrice - {price}%0ATotal Area - {totalArea}%0ALayout - {layoutName}%0AFinishing - {decorationType}%0A%0AWhen can we see it?",
        "messageInLayoutForWhatsApp": "I want to book an apartment with Layout - {layoutName} in {buildingName}, House {houseName}%0APrice - {price}%0ATotal Area - {totalArea}%0A%0AWhen can we see it?",
        "messageDefaultField": "not specified"
      }
    },
    "statistics": {
      "price": {
        "title": "Dynamics of prices per m²",
        "toggle": {
          "all": "All",
          "studio": "Studio",
          "one": "1 bedroom",
          "two": "2 bedroom",
          "three": "3 bedroom"
        },
        "forSquareMeter": "Price per {currency} m²",
        "table": {
          "date": "Date",
          "newBuildingPerSquareMeter": "New building per m²",
          "button": "View all"
        },
        "notFound": "Unfortunately, no statistics were found"
      }
    }
  },
  "layout": {
    "entityName": "Layouts",
    "image": {
      "alt": "Layout"
    }
  },
  "developer": {
    "entityName": "Developer",
    "houses": {
      "handOver": "were handover in",
      "unfinished": "are being built in"
    },
    "foundationYear": "year the company was founded",
    "page": {
      "description": {
        "title": "About the developer",
        "fullShowButton": "Read more"
      },
      "contacts": {
        "info": {
          "text": "To view the developer's contacts,",
          "login": "log in",
          "signUp": "sign up"
        }
      },
      "advantages": "Advantages",
      "buildings": {
        "title": "Buildings from the developer",
        "showButton": "Show {count} more buildings"
      },
      "flats": {
        "title": "Apartments from the developer",
        "showButton": "Show {count} more flats"
      }
    },
    "promotions": {
      "label": "Developer promotions",
      "allPromotions": "All developer promotions",
      "showAllPromotions": "View all developer promotions",
      "showButton": "View",
      "promotionsNotFound": "Promotions not found"
    }
  },
  "house": {
    "count": "{count} houses|{count} house|{count} houses|{count} houses",
    "type": {
      "label": "Building Type",
      "options": {
        "block": "Block",
        "frame": "Frame",
        "monolithicFrame": "Monolithic Frame"
      }
    },
    "facadeType": {
      "label": "Facade",
      "options": {
        "plaster": "Plaster",
        "ventilatedFacade": "Ventilated Facade",
        "glass": "Glass"
      }
    },
    "parkingType": {
      "label": "Parking",
      "options": {
        "underground": "Underground",
        "indoor": "Indoor",
        "inYard": "In Yard"
      }
    },
    "lifts": {
      "label": "Lift",
      "noLifts": "No Lift",
      "liftsExist": "Lifts Available",
      "passengerLiftsCount": {
        "label": "{count} passenger lifts|{count} passenger lift|{count} passenger lifts|{count} passenger lifts"
      },
      "cargoLiftsCount": {
        "label": "{count} cargo lifts|{count} cargo lift|{count} cargo lifts|{count} cargo lifts"
      }
    }
  },
  "entityActions": {
    "filter": {
      "promotions": {
        "hasPromotions": "Participates in promotions"
      },
      "entitiesFilter": {
        "placeholder": "Specify the residential complex, developer",
        "noCountryPlaceholder": "Please select a country first"
      },
      "flatFilter": {
        "kitchenArea": {
          "label": "Kitchen Area",
          "from": "Kitchen Area from {value} sqm",
          "to": "Kitchen Area up to {value} sqm",
          "action": {
            "label": "Select Kitchen Area"
          }
        },
        "totalArea": {
          "label": "Total Area",
          "from": "Total Area from {value} sqm",
          "to": "Total Area up to {value} sqm"
        },
        "price": {
          "label": "Price",
          "from": "Price from {value}",
          "to": "Price up to {value}"
        },
        "flatRoomsCount": {
          "one": "1 bdrm",
          "two": "2 bdrm",
          "three": "3 bdrm",
          "four": "4 bdrm",
          "five": "5 bdrm",
          "six": "6 bdrm",
          "studio": "studio"
        },
        "flatNumber": {
          "placeholder": "Enter flat number"
        }
      },
      "houseFilter": {
        "deadLineYear": {
          "label": "Handover",
          "from": "Handover from {quarter} qtr. {year}",
          "to": "Handover up to {quarter} qtr. {year}",
          "showHandOver": "Show handed over buildings"
        },
        "keysGetting": {
          "label": "Keys Getting",
          "from": "Keys Getting from {quarter} qtr. {year}",
          "to": "Keys Getting up to {quarter} qtr. {year}",
          "showHandOver": "Show handed over buildings",
          "action": {
            "label": "Select Keys Getting"
          }
        },
        "lifts": {
          "label": "Lifts",
          "hasPassenger": {
            "label": "Passenger Lifts"
          },
          "hasCargo": {
            "label": "Cargo Lifts"
          }
        },
        "materialTypes": {
          "label": "Material",
          "action": {
            "label": "Select Material"
          },
          "aerocreteBlock": {
            "label": "Aerocrete Block"
          },
          "block": {
            "label": "Block"
          },
          "boards": {
            "label": "Boards"
          },
          "brick": {
            "label": "Brick"
          },
          "foamConcreteBlock": {
            "label": "Foam Concrete Block"
          },
          "gasSilicateBlock": {
            "label": "Gas Silicate Block"
          },
          "monolith": {
            "label": "Monolithic"
          },
          "monolithBrick": {
            "label": "Monolithic Brick"
          },
          "old": {
            "label": "Old Fund"
          },
          "panel": {
            "label": "Panel"
          },
          "stalin": {
            "label": "Stalinist"
          },
          "wireframe": {
            "label": "Wireframe"
          },
          "wood": {
            "label": "Wood"
          },
          "log": {
            "label": "Log"
          },
          "timber": {
            "label": "Timber"
          }
        }
      },
      "allFilters": {
        "label": "All Filters",
        "modal": "More Filters"
      },
      "savedFilters": {
        "label": {
          "short": "Saved Filters",
          "full": "Saved Filters"
        },
        "modal": "Saved Filters"
      },
      "selectedFilters": {
        "show": "More {value}"
      },
      "button": "Filter",
      "resetButton": "Reset",
      "actions": {
        "resetFilters": "Reset Filters"
      },
      "guest": {
        "title": {
          "partOne": "International",
          "partTwo": "realty ecosystem"
        }
      }
    },
    "search": {
      "flat": "no flats found | {count} flat | {count} flats | {count} flats",
      "mobileFlat": "0 flats | {count} flat | {count} flats | {count} flats",
      "noFlat": "No available flats",
      "shortSearchButton": "{count} flats | {count} flat | {count} flats | {count} flats",
      "errors": {
        "first": "An error occurred. Please try refreshing the page.",
        "second": "An error occurred. Please contact support."
      }
    },
    "show": {
      "layouts": "{count} layouts | {count} layout | {count} layouts | {count} layouts",
      "flats": "{count} flats | {count} flat | {count} flats | {count} flats",
      "buildings": "{count} buildings | {count} building | {count} buildings | {count} buildings"
    },
    "showCount": {
      "layouts": "No layouts found | Found {count} layout | Found {count} layouts | Found {count} layouts",
      "flats": "No flats found | Found {count} flat | Found {count} flats | Found {count} flats",
      "buildings": "No buildings found | in {count} buildings | in {count} buildings | in {count} buildings"
    },
    "sort": {
      "options": {
        "price": {
          "label": "Price",
          "order": {
            "descending": "High to Low",
            "ascending": "Low to High"
          }
        },
        "popularity": {
          "label": "By popularity",
          "order": {
            "descending": "Most popular first",
            "ascending": "Least popular first"
          }
        },
        "deadline": {
          "label": "Handover",
          "order": {
            "descending": "Early First",
            "ascending": "Late First"
          }
        },
        "area": {
          "label": "Area",
          "order": {
            "descending": "Large to Small",
            "ascending": "Small to Large"
          }
        },
        "floor": {
          "label": "Floor",
          "order": {
            "descending": "Top Floors First",
            "ascending": "Bottom Floors First"
          }
        },
        "percentage": {
          "label": "By commission percentage",
          "order": {
            "descending": "Lower first",
            "ascending": "Higher first"
          }
        }
      }
    },
    "showMap": "On Map",
    "mapBuildings": "{count} buildings | {count} building | {count} buildings | {count} buildings"
  },
  "user": {
    "profile": {
      "avatar": {
        "alt": "User Avatar {user}"
      },
      "page": {
        "toggle": {
          "options": {
            "profile": "Profile",
            "login": "Login",
            "password": "Password"
          }
        },
        "deleteAccount": {
          "title": "Account deleting",
          "inputTitle": "Indicate the reason for deletion",
          "buttonTitle": "Delete account",
          "sendRequest": "Send request",
          "toast": {
            "success": "Account deletion request sent",
            "error": "An error occurred while sending the deletion request"
          }
        }
      },
      "form": {
        "profile": {
          "title": {
            "edit": "Edit employee"
          },
          "about": {
            "title": "About user",
            "firstName": {
              "label": "Name",
              "placeholder": "Enter name"
            },
            "lastName": {
              "label": "Last name",
              "placeholder": "Enter last name"
            }
          },
          "contacts": {
            "title": "Contacts",
            "mobilePhone": {
              "label": "Phone number",
              "placeholder": "+7 000 000 00 00"
            },
            "email": {
              "label": "E-mail"
            },
            "telegramPhone": {
              "label": "Telegram number",
              "placeholder": "+7 000 000 00 00"
            },
            "telegramLogin": {
              "label": "Telegram username",
              "placeholder": "Username"
            },
            "whatsappPhone": {
              "label": "Whatsapp number",
              "placeholder": "+7 000 000 00 00"
            }
          },
          "toasts": {
            "profile": {
              "success": "Profile successfully updated",
              "error": "An error occurred while updating the profile"
            },
            "login": {
              "success": "Login successfully updated",
              "error": "An error occurred while updating the login"
            },
            "password": {
              "success": "Password successfully updated",
              "error": "An error occurred while updating the password"
            },
            "image": {
              "success": "Image updated successfully",
              "error": "An error occurred while updating the image"
            }
          }
        },
        "login": {
          "title": "Change login",
          "mobilePhone": {
            "label": "Phone number"
          },
          "currentPassword": {
            "label": "Current password"
          },
          "submit": "Change login"
        },
        "password": {
          "title": "Change password",
          "password": {
            "label": "New password"
          },
          "repeatPassword": {
            "label": "Repeat new password"
          },
          "submit": "Change password"
        }
      },
      "personalCabinet": {
        "goTo": {
          "label": "Go to Profile",
          "confirmation": {
            "title": "Confirmation",
            "text": "You can manage your settings and profile in our CRM Quick Deal.\nYour login and password are the same as in Estatyx.\nThis is a temporary solution and in the near future, profile and settings management will be available in Estatyx.\nYou can also familiarize yourself with our CRM for real estate agencies Quick Deal =)\nThank you for understanding."
          }
        }
      }
    }
  },
  "mainMenu": {
    "navLinks": {
      "home": {
        "index": "Main",
        "market": "Сatalog",
        "popular": "Popular",
        "prices": "Prices",
        "partners": "Partners",
        "mortgage": "Mortgage",
        "journal": "Journal",
        "events": "Events"
      }
    }
  },
  "navMenu": {
    "title": "Menu",
    "clients": {
      "label": "Customer interaction mode",
      "motivation": "Motivation",
      "crm": "CRM"
    },
    "invite": {
      "buttonTitle": "Invite Employees",
      "modalTitle": "Invitation Link",
      "tooltip": "Click to copy",
      "toast": {
        "success": "Link copied",
        "changeLink": {
          "success": "Link updated",
          "error": "An error occurred while updating the link"
        }
      }
    },
    "organisationSettings": {
      "buttonTitle": "Settings"
    },
    "navigation": {
      "suggestions": "Collections",
      "clients": "Clients",
      "home": "Home",
      "market": "Catalog",
      "journal": "Journal",
      "events": "Events",
      "settings": "Settings"
    },
    "exit": "Exit",
    "changeList": {
      "label": "Updates",
      "version": "Version",
      "changeListItem": "Update",
      "badge": "NEW"
    }
  },
  "suggestions": {
    "sidePanel": {
      "displayAllFlats": {
        "button": "Show All",
        "title": "Currently displaying all flats from {value} suggested buildings",
        "tip": "To view all flats from a specific building, click on the Buildings tab at the top right"
      }
    },
    "content": {
      "buildingEmpty": "No added flats in the selected building.",
      "suggestionEmpty": "No added flats in the selected suggestion."
    },
    "buttons": {
      "bookViewing": {
        "label": "Book Viewing",
        "messageForWhatsApp": "I want to schedule a viewing of flats from the suggestion {link}. When can we see them?"
      },
      "chatWithManager": {
        "label": "Chat with Manager"
      }
    },
    "bottomMenu": {
      "contacts": "Contacts"
    },
    "empty": "Suggestion is empty",
    "textToSend": "Collection of objects: {value}"
  },
  "suggestionsActions": {
    "filter": {
      "all": "All",
      "user": "My"
    },
    "create": {
      "title": {
        "full": "Create Suggestion",
        "short": "Suggestion"
      },
      "placeholder": "Enter suggestion name",
      "toast": {
        "success": "Suggestion created successfully"
      }
    },
    "addToSuggestion": {
      "title": "Add objects to suggestion",
      "toast": {
        "success": "Objects added to suggestion"
      }
    },
    "removeSuggestion": {
      "toast": {
        "success": "Suggestion deleted successfully",
        "error": "An error occurred"
      },
      "confirm": "Are you sure you want to delete the suggestion \"{value}\"?"
    },
    "client": {
      "search": {
        "title": "Find Existing Client",
        "placeholder": "Enter Full Name, Phone, or Email"
      },
      "add": {
        "toast": {
          "success": "Client successfully added to suggestion."
        }
      },
      "remove": {
        "toast": {
          "success": "Client successfully removed from suggestion."
        }
      },
      "create": {
        "title": "Create New Client",
        "fullName": {
          "label": "Client's Full Name",
          "placeholder": "Enter Client's Full Name"
        },
        "mobilePhone": {
          "label": "Phone",
          "placeholder": "+_ (___) ___-__-__"
        },
        "email": {
          "label": "Email",
          "placeholder": "Enter Email"
        },
        "submit": "Add Client",
        "toast": {
          "success": "Client successfully created.",
          "error": "An error occurred. Please reload the page. If the problem persists, please contact us."
        }
      },
      "noClients": "No client selected"
    },
    "removeBuildingFromSuggestion": {
      "toast": {
        "success": "Suggestion updated"
      },
      "confirm": "Are you sure you want to remove {value} and all its flats from the suggestion?"
    },
    "removeFlatFromSuggestion": {
      "toast": {
        "success": "Suggestion updated"
      },
      "confirm": "Are you sure you want to remove flat number {value} from the suggestion?"
    },
    "copyLink": {
      "toast": {
        "success": "Link to suggestion copied to clipboard"
      }
    },
    "table": {
      "suggestionTitle": "Suggestion Title",
      "objects": "Objects",
      "clients": "Clients",
      "creatingDate": "Creation Date",
      "itemsCount": {
        "buildings": "{value} Buildings",
        "flats": "{value} Flats"
      }
    },
    "view": {
      "empty": "Suggestion is empty"
    }
  },
  "clients": {
    "page": {
      "title": "Clients",
      "create": {
        "buttonTitle": {
          "full": "Add Client",
          "short": "Client"
        }
      }
    },
    "toasts": {
      "booking": {
        "success": "Booking was successful",
        "error": "An error occurred during booking"
      },
      "fixation": {
        "success": "Fixation was successful",
        "error": "An error occurred during fixation"
      }
    },
    "tableView": {
      "fullName": "Full Name",
      "email": "Email",
      "mobilePhone": "Phone",
      "action": "Action"
    },
    "delete": {
      "toast": {
        "success": "Client deleted successfully."
      }
    },
    "table": {
      "label": "All clients",
      "actions": {
        "addToSuggestion": "Add to suggestion",
        "bookings": "Client bookings",
        "fixations": "Client fixations",
        "views": "Client views",
        "edit": "Edit client",
        "delete": "Delete client"
      }
    },
    "filter": {
      "categories": {
        "clients": "Client",
        "developers": "Developers",
        "buildings": "Buildings",
        "assignees": "Responsibles"
      },
      "selected": "Selected",
      "excluded": "Excluded"
    },
    "fixation": {
      "table": {
        "label": "Fixations",
        "createDate": "Date created",
        "client": "Client",
        "developer": "Developer",
        "status": "Booking status",
        "endDate": "Book until",
        "assigned": "Responsible"
      },
      "actions": {
        "add": "Fix"
      },
      "form": {
        "progress": "Booking",
        "progressStep": "+{value}% to progress",
        "submit": "Fix",
        "developer": {
          "title": "Developer",
          "label": "Developer selection",
          "placeholder": "List of developers",
          "conditions": {
            "general": "General conditions",
            "custom": "Fixation conditions"
          }
        },
        "user": {
          "title": "Responsible",
          "label": "User selection",
          "placeholder": "List of users",
          "select": "Select"
        },
        "client": {
          "title": "Client",
          "label": "Select a client or create a new one",
          "add": "Add a new client",
          "placeholder": "Enter name, phone, or email to search.",
          "noClients": "Nothing found, adjust your search or create a new client"
        },
        "comment": {
          "label": "Comment",
          "placeholder": "Your comment"
        }
      },
      "info": {
        "title": "Advantage",
        "text": "You can read about the advantages of client fixation in our article",
        "link": "Read here"
      },
      "warning": {
        "title": "General conditions",
        "text": "If the developer does not specify the conditions for client's fixation, then the client's fixation period is",
        "fixPeriod": "14 days"
      },
      "fixationTerms": {
        "title": "Fixation",
        "durationInDays": "Fixation period",
        "clientsData": "Required client data",
        "additionalInfo": "Additional information"
      },
      "contacts": {
        "title": "Contacts for communication",
        "mobileTitle": "Contacts"
      }
    },
    "booking": {
      "table": {
        "label": "Bookings",
        "createDate": "Date created",
        "client": "Client",
        "developer": "Developer",
        "building": "Residential complex",
        "flat": "Apartment",
        "status": "Booking status",
        "endDate": "Book until",
        "paymentType": "Payment",
        "assigned": "Responsible"
      },
      "actions": {
        "add": "Book"
      },
      "paymentType": {
        "options": {
          "mortgage": "Mortgage",
          "cash": "Сash"
        }
      },
      "form": {
        "progress": {
          "yourCommission": "Your commission"
        },
        "deal": {
          "title": "Deal",
          "label": "Form of payment",
          "placeholder": "Select form of payment",
          "uploadButton": "Upload scans of documents"
        },
        "about": {
          "flat": "About the apartment"
        },
        "submit": "Book"
      },
      "warning": {
        "text": "Maximum booking period - 3 days"
      }
    },
    "views": {
      "table": {
        "label": "Views",
        "createDate": "Create Date",
        "dateView": "View Date",
        "client": "Client",
        "building": "Building",
        "flat": "Apartment",
        "assigned": "Responsible",
        "comment": "Comment",
        "view": "View",
        "status": "View Status"
      },
      "statuses": {
        "scheduled": "Assigned",
        "done": "Conducted",
        "toSchedule": "Schedule Approval"
      },
      "actions": {
        "edit": "Edit view",
        "add": "Add",
        "selectStatus": "Select status",
        "save": "Save view",
        "addView": "Add view",
        "selectBuilding": "Select building",
        "selectFlat": "Select flat"
      },
      "status": "Status",
      "building": "Residence",
      "chessboard": "Chessboard",
      "aboutClient": "About client",
      "toasts": {
        "create": {
          "success": "View created successfully",
          "error": "An error occurred while creating the view"
        },
        "update": {
          "success": "View successfully updated",
          "error": "An error occurred while updating the view"
        }
      }
    }
  },
  "mortgage": {
    "label": "Mortgage",
    "calculator": {
      "inputs": {
        "price": {
          "label": {
            "full": "Realty Price",
            "short": "Price"
          }
        },
        "initialPayment": {
          "label": {
            "full": "Initial Payment",
            "short": "Init. Pay."
          }
        },
        "term": {
          "label": "Term",
          "suffix": "year | year | years | years"
        },
        "stake": {
          "label": "Stake",
          "suffix": "year"
        }
      },
      "info": {
        "monthlyPayment": "Monthly Payment",
        "recommendedIncome": "Recommended Income"
      }
    },
    "table": {
      "bank": {
        "label": "Bank"
      },
      "stake": {
        "label": "Interest Rate",
        "stakeRange": {
          "inText": "from {value}%",
          "default": "From {value}%"
        }
      },
      "maxSum": {
        "label": "Sum",
        "range": "to {sum}"
      },
      "term": {
        "label": "Term",
        "termRange": "Term not selected | {count} year | {count} years | {count} years"
      },
      "monthlyPayment": {
        "label": "Monthly Payment"
      },
      "initialPayment": {
        "label": {
          "full": "Initial Payment",
          "short": "Initial"
        },
        "initialPaymentRange": "from {value}%"
      }
    },
    "schedule": {
      "title": "Loan payment schedule",
      "price": "Price",
      "creditAmount": "Loan amount",
      "term": "Term",
      "overpayment": "Overpayment",
      "table": {
        "date": "Date",
        "remainder": "Remainder",
        "stakes": "Stakes",
        "redeemed": "Redeemed",
        "payment": "Payment"
      }
    },
    "mortgageActions": {
      "showMortgageList": "No programs found | View {count} program | View all {count} programs | View all {count} programs",
      "showSchedule": "Payment schedule",
      "applyOnline": "Apply Online"
    }
  },
  "files": {
    "label": "Files",
    "actions": {
      "link": "Copy",
      "go": "Go",
      "show": "Show All"
    },
    "toast": {
      "success": "File link copied to clipboard"
    }
  },
  "chessboard": {
    "house": {
      "empty": "No sections in the selected building"
    },
    "section": {
      "label": "Section",
      "empty": "No buildings in the selected residential complex"
    },
    "floorNumber": {
      "label": "Floor"
    },
    "modal": {
      "title": "Flat Information"
    },
    "empty": "No flats in the selected building that match the specified filters"
  },
  "toast": {
    "error": "An error occurred",
    "title": {
      "success": "Success",
      "info": "Tip",
      "warning": "Warning",
      "danger": "Tip"
    }
  },
  "errorPage": {
    "notFound": "Page not found!",
    "forbidden": "Access forbidden!",
    "backLink": "Go back to homepage"
  },
  "map": {
    "actions": {
      "showGrid": "Show Grid",
      "openFilter": "Filter",
      "paintOn": "Highlight Area",
      "painting": "Start painting"
    },
    "building": {
      "notFound": "No buildings found in the area"
    },
    "paintedGeometries": "No highlighted areas | {count} area on the map | {count} areas on the map | {count} areas on the map"
  },
  "footer": {
    "copyright": {
      "title": "©{year} OOO \"MEN\". All rights reserved.",
      "subtitle": "When using materials, a hyperlink is required."
    },
    "subscribe": {
      "title": "Subscribe to our newsletter",
      "inputPlaceholder": "Email",
      "button": "Subscribe"
    }
  },
  "partners": {
    "title": "Partners",
    "toggle": {
      "developers": "Developers",
      "agencies": "Agencies",
      "banks": "Banks"
    },
    "developers": {
      "houses": {
        "count": "{count} houses",
        "built": "built in",
        "unfinished": "under construction in"
      }
    },
    "agency": {
      "offers": "No offers | {count} offer | {count} offers | {count} offers"
    },
    "bank": {
      "mortgage": "Mortgage from {stake}%"
    }
  },
  "journal": {
    "title": "Journal",
    "goButton": "Go to journal",
    "type": {
      "options": {
        "news": "News | News | News | News",
        "promotion": "Promotion | Promotion | Promotions | Promotions",
        "article": "Article | Article | Articles | Articles",
        "review": "Review | Review | Reviews | Reviews",
        "training": "Training | Training | Trainings | Trainings",
        "webinar": "Webinar | Webinar | Webinars | Webinars",
        "excursion": "Excursion | Excursion | Excursions | Excursions"
      }
    },
    "form": {
      "title": {
        "add": "Add content"
      },
      "sectionTitle": {
        "content": "Сontent",
        "object": "Object",
        "author": "Author",
        "date": "Date",
        "seats": "Number of seats"
      },
      "fields": {
        "title": {
          "label": "Title",
          "placeholder": "Article title"
        },
        "text": {
          "label": "Text",
          "placeholder": "Article text"
        },
        "shortText": {
          "label": "Brief description",
          "placeholder": "Article text"
        },
        "promotionType": {
          "label": "Promotion type",
          "placeholder": "Select",
          "options": {
            "discount": "Discount",
            "sale": "Sale",
            "promotion": "Promotion",
            "increasedPercentage": "Increased percentage",
            "increasedPercentageShort": "Incr. percent."
          }
        },
        "file": {
          "button": "Upload preview"
        },
        "bannerFile": {
          "button": "Upload banner"
        },
        "video": {
          "label": "Add a video",
          "placeholder": "Link to video",
          "caption": "This video will be hidden behind the article preview, but the user will be able to view it",
          "presentationVisible": "Do not display in presentation"
        },
        "developer": {
          "label": "Developer",
          "placeholder": "List of developers",
          "isAll": "All developers",
          "isManagerRequestNeeded": "Check with the manager"
        },
        "building": {
          "label": "Residential Complex",
          "placeholder": "List of residential complexes",
          "isAll": "All residential complexes",
          "isManagerRequestNeeded": "Check with the manager"
        },
        "country": {
          "label": "Country",
          "placeholder": "List of countries",
          "isAll": "All countries",
          "isManagerRequestNeeded": "Check with the manager"
        },
        "address": {
          "label": "Region/City",
          "placeholder": "List of regions/cities",
          "isAll": "All regions/cities",
          "isManagerRequestNeeded": "Check with the manager"
        },
        "authorship": {
          "label": "Authorship",
          "options": {
            "estatyx": "Estatyx",
            "company": "Company"
          }
        },
        "company": {
          "label": "Select a company",
          "placeholder": "List of companies"
        },
        "user": {
          "label": "Select a company employee",
          "placeholder": "List of employees"
        },
        "date": {
          "label": "Date",
          "placeholder": "Select the date"
        },
        "dateRange": {
          "label": "Select the date"
        },
        "time": {
          "label": "Time"
        },
        "registrationEndDate": {
          "label": "Registation end date",
          "placeholder": "Select the registation end date",
          "validation": "{endDate} cannot be later than {startDate}"
        },
        "duration": {
          "label": "Duration",
          "unlimited": "Indefinitely"
        },
        "durationInDays": {
          "label": "Days"
        },
        "durationInHours": {
          "label": "Hours"
        },
        "seats": {
          "label": "Number of seats",
          "placeholder": "Enter the number of available seats",
          "checkbox": "Unlimited number of seats"
        }
      },
      "toast": {
        "create": {
          "success": "Article successfully created"
        },
        "edit": {
          "success": "Article successfully edited"
        }
      }
    },
    "search": {
      "notFound": "No news found"
    },
    "filter": {
      "withVideo": "Only with video",
      "promotions": "Promotions",
      "buildings": "Buildings",
      "developers": "Developers"
    },
    "page": {
      "video": "Video",
      "event": {
        "info": {
          "text": "To take part in the event,",
          "login": "log in",
          "signUp": "sign up"
        }
      }
    }
  },
  "events": {
    "page": {
      "title": "Events"
    },
    "seatsCount": "{count} seats left | {count} seat left | {count} seats left | {count} seats left",
    "mySignUps": "My event entries",
    "status": {
      "seatsUnlimited": "Seats avialable",
      "noSeats": "No seats left",
      "registrationCompleted": "Registration Completed"
    },
    "filter": {
      "name": "Search..."
    },
    "search": {
      "notFound": "No events found"
    },
    "actions": {
      "signUp": "Sign up",
      "canсelSignUp": "Cancel sign up",
      "noSeats": "No seats",
      "registrationCompleted": "Registration Completed",
      "eventEnded": "Event ended"
    },
    "toast": {
      "signUp": {
        "success": "You have successfully registered for the event!"
      },
      "canсelSignUp": {
        "success": "Registration cancelled!"
      },
      "error": "An error occurred while loading events"
    },
    "tooltipHint": "Recording is available only to registered users"
  },
  "popularity": {
    "suggestionsAdditionsCount": "Added to the collection in {count} months",
    "totalSuggestionsAdditionsCount": "For all time added to the collection {count} time | For all time added to the collection {count} times | For all time added to the collection {count} times | For all time added to the collection {count} times"
  }
}
